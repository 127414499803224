import { createRouter, createWebHashHistory } from 'vue-router'
import ProductList from '../components/ProductList.vue'
import ProductDetail from '../components/ProductDetail.vue'
import OrderPage from '../components/OrderPage.vue'

const routes = [
  { path: '/', component: ProductList, meta: { title: '万家🎆' }},
  { path: '/product/', component: ProductDetail, meta: { title: '万家🎆' } },
  { path: '/order', name: 'Order', component: OrderPage, meta: { title: '提交订单' } },
  {
    path: '/i/:code',
    name: 'Invitation',
    component: () => import('../components/InvitationRedirect.vue'),
  },
  {
    path: '/orders',
    name: 'OrderHistory',
    component: () => import('../components/OrderHistory.vue')
  },
  {
    path: '/peisong',
    name: 'DeliveryManagement',
    component: () => import('../components/DeliveryManagement.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
