<template>
  <div class="product-detail">
    <!-- 主图 -->
    <div class="main-image-container">
      <img :src="getImageUrl(product.description)" alt="商品缩略图" class="main-image" />
    </div>

    <!-- 商品信息 -->
    <div class="product-info">
      <h3>{{ product.name }}</h3>
      <p class="price">¥{{ product.price }}</p>
      <div class="product-actions">
        <el-button @click.stop="addToCart(product)" class="add-to-cart-btn" type="primary" round size="small">
          <el-icon><Plus /></el-icon> 加入购物车
        </el-button>
      </div>
    </div>

    <!-- 详情图片 -->
    <div class="detail-images">
      <img 
        v-for="url in getDescriptionUrl(product.description)" 
        :key="url" 
        :src="url" 
        alt="商品详情图" 
        class="detail-image"
      />
    </div>

    <!-- 其他组件保持不变 -->
    <gift-selector
      v-model:visible="giftSelectorVisible"
      :product="selectedProduct"
      @select="handleGiftSelect"
    />
    <shopping-cart />
  </div>
</template>

<script>
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import { Plus } from '@element-plus/icons-vue'
import GiftSelector from './GiftSelector.vue'
import ShoppingCart from './ShoppingCart.vue'
import { useCart } from '../services/cartService'


export default {
  name: 'ProductDetail',
  components: { Plus, GiftSelector, ShoppingCart },
  setup() {
    const route = useRoute()
    const productId = route.query.id
    // 从 localStorage 获取商品列表
    const productsList = JSON.parse(localStorage.getItem('products') || '[]')
    const product = ref(productsList.find(p => p.id === parseInt(productId)) || {})
    
    const giftSelectorVisible = ref(false)
    const selectedProduct = product
    
    // 处理图片 URL
    const getImageUrl = (description) => {
      try {
        const imageList = JSON.parse(description)
        console.log(imageList)
        return `https://www.maiyanhua.com.cn/${imageList[0]}`
      } catch (error) {
        console.error('Error parsing image description:', error)
        return ''
      }
    }

    const getDescriptionUrl = (description) => {
      try {
        const imageList = JSON.parse(description).map(item => `https://www.maiyanhua.com.cn/${item}`)
        // 从第2张图片开始
        return imageList.slice(1)
      } catch (error) {
        console.error('Error parsing image description:', error)
        return ''
      }
    }

    // 加入购物袋
		const addToCart = () => {
			giftSelectorVisible.value = true
		}

    const handleGiftSelect = (giftType) => {
      const { addToCart } = useCart()
      addToCart(selectedProduct.value, giftType)
    }


    return {
      product,
      addToCart,
      handleGiftSelect,
      getImageUrl,
      getDescriptionUrl,
      selectedProduct,
      giftSelectorVisible
    }
  }
}
</script>

<style scoped>
.product-detail {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-image-container {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #fff;
}

.main-image {
  width: 100%;
  max-width: 800px;
  height: auto;
  object-fit: contain;
}

.product-info {
  width: 100%;
  max-width: 800px;
  padding: 20px;
  box-sizing: border-box;
}

.price {
  color: #f56c6c;
  font-size: 20px;
  font-weight: bold;
}

.detail-images {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.detail-image {
  width: 100%;
  max-width: 800px;
  height: auto;
  display: block;
  margin: 0; /* 移除图片间距 */
}

.product-actions {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
}

.add-to-cart-btn {
  padding: 10px;
  font-size: 16px;
  /* display: flex; */
  /* align-items: center; */
  /* gap: 5px; */
}

/* 移除之前的样式 */
.product-image {
  display: none;
}
</style>