<template>
  <el-dialog
    v-model="dialogVisible"
    title="请选择赠品"
    width="300px"
    :show-close="false"
    center
  >
    <div class="gift-options">
        <div class="gift-button-wrapper" v-for="gift in giftOptions" :key="gift.type">
        <el-button
          class="gift-button"
          @click="selectGift(gift.type)"
        >
          {{ gift.label }} (+¥{{ gift.price }})
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script setup>
import { ref, defineEmits, watch, defineProps } from 'vue'

const props = defineProps({
  visible: Boolean,
  product: Object,
})

const emit = defineEmits(['update:visible', 'select'])

const dialogVisible = ref(props.visible)

const giftOptions = [
  { type: 'small', label: '小礼盒', price: 10 },
  { type: 'medium', label: '中礼盒', price: 20 },
  { type: 'large', label: '大礼盒', price: 30 },
]

const selectGift = (giftType) => {
  emit('select', giftType)
  dialogVisible.value = false
}

// 监听visible属性变化
watch(() => props.visible, (newVal) => {
  dialogVisible.value = newVal
})

// 监听对话框关闭
watch(dialogVisible, (newVal) => {
  if (!newVal) {
    emit('update:visible', false)
  }
})
</script>

<style scoped>
.gift-options {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.gift-button-wrapper {
  width: 100%;
}

.gift-button {
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 16px;
  transition: all 0.3s;
}

.gift-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}
</style> 