<template>
  <div class="order-page">
    <el-card class="order-form">
      <div style="color: red;">只派送南沙区，货到付款，当前为预定单</div>
      <h2>订单信息</h2>
      <el-form :model="form"  label-width="120px">
        <el-form-item label="收货地址：" prop="address" required>
          <div class="textarea-wrapper">
            <div class="textarea-prefix">广州市南沙区</div>
            <el-input
              type="textarea"
              v-model="form.address"
              placeholder="请输入街道和小区门牌号"
              :rows="3"
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item label="联系电话" prop="phone" required>
          <el-input v-model="form.phone" placeholder="请输入收货人电话"></el-input>
        </el-form-item>
        <el-form-item label="收货人" prop="name" required>
          <el-input v-model="form.name" placeholder="请输入收货人姓名"></el-input>
        </el-form-item>

        
        <el-form-item label="备注" prop="note">
          <el-input v-model="form.note" type="textarea" placeholder="请输入备注信息"></el-input>
        </el-form-item>

        <el-form-item label="预期送货时间" prop="deliveryTime">
          <el-date-picker
            v-model="form.deliveryTime"
            type="datetime"
            placeholder="选择送货时间(可为空)"
            time-format="HH:mm"
            teleported
            :disabled-date="disabledDate"
          />
        </el-form-item>
      </el-form>
    </el-card>

    <el-card class="order-items">
      <h2>订单商品</h2>
      <div class="cart-items">
        <div v-for="item in cart" :key="`${item.name}-${item.giftType}`" class="cart-item">
          <div class="item-info">
            <h4>{{ item.name }}</h4>
            <p class="gift-type">{{ getGiftLabel(item.giftType) }}</p>
          </div>
          <div class="item-quantity">
            数量: {{ item.quantity }}
          </div>
          <div class="item-price">
            ¥{{ item.price * item.quantity }}
          </div>
        </div>
      </div>

      <div class="order-total">
        <span class="total-label">订单总计:</span>
        <span class="total-amount">¥{{ totalAmount }}</span>
      </div>

      <div class="submit-section">
        <el-button type="primary" size="large" @click="submitOrder" :loading="submitting">
          提交订单
        </el-button>
      </div>
    </el-card>
    <div style="height: 300px;"></div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useCart } from '../services/cartService'
import { ElMessage } from 'element-plus'

const router = useRouter()
const { cart, totalAmount } = useCart()
const submitting = ref(false)

// import { reactive } from 'vue';

const form = ref({
  address: '',
  phone: '',
  name: '',
  note: '',
  deliveryTime: null,
})

const getGiftLabel = (type) => {
  const labels = {
    small: '小礼盒',
    medium: '中礼盒',
    large: '大礼盒'
  }
  return labels[type]
}

// 禁用所有分钟，只保留00分
// const disabledMinutes = () => {
//   return Array.from({ length: 60 }, (_, i) => i).filter(m => m !== 0)
// }

// // 禁用所有秒数
// const disabledSeconds = () => {
//   return Array.from({ length: 60 }, (_, i) => i)
// }


// const disabledHours = () => {
  //   return [0, 1, 2, 3, 4, 5, 6, 22, 23]
  // }
  
  
  const disabledDate = (time) => {
    return time.getTime() < Date.now()
  }
const submitOrder = async () => {
  // if (!form.value.address || !form.value.phone || !form.value.name || !form.value.deliveryTime) {
  //   ElMessage.error('请填写所有必填信息')
  //   return
  // }

  const phoneRegex = /^1[3-9]\d{9}$/
  if (!phoneRegex.test(form.value.phone)) {
    ElMessage.error('请输入正确的手机号码')
    return
  }
 // 拼接商品信息
 const itemsInfo = cart.value.map(item => {
    const giftLabel = getGiftLabel(item.giftType)
    return `${item.name}(${giftLabel}) x ${item.quantity}`
  }).join('，')

  submitting.value = true
  try {
    const response = await fetch('https://www.maiyanhua.com.cn/api/order/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        address: form.value.address,
        phone: form.value.phone,
        name: form.value.name,
        note: form.value.note,
        delivery: form.value.deliveryTime,
        item: itemsInfo, 
        price: totalAmount.value,
        user: localStorage.getItem('user')
      })
    })

    if (response.ok) {
      const orderData = await response.json()
      
      // 从缓存中获取现有订单列表
      const existingOrders = JSON.parse(localStorage.getItem('orders') || '[]')
      
      // 添加新订单到列表
      existingOrders.push(orderData)
      
      // 更新缓存
      localStorage.setItem('orders', JSON.stringify(existingOrders))
      console.log(existingOrders)

      ElMessage.success('订单提交成功')
      localStorage.removeItem('cart')
      router.push('/')
    } else {
      throw new Error('订单提交失败')
    }
  } catch (error) {
    ElMessage.error(error.message)
  } finally {
    submitting.value = false
  }
}
</script>

<style scoped>
@media screen and (max-width: 768px) {
  .el-picker-panel {
    width: 90vw !important; /* 弹出框占据屏幕的 90% 宽度 */
    left: 5vw !important;   /* 居中显示 */
    right: auto !important;
  }
}
.textarea-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.textarea-prefix {
  white-space: nowrap; /* 防止换行 */
  font-size: 14px;
  color: #606266; /* Element Plus 的辅助文字颜色 */
  padding: 4px 0;
}

.el-input__inner {
  flex: 1;
}
.order-page {
  max-width: 800px;
  margin: 20px auto;
  padding: 0 20px;
}

.order-form, .order-items {
  margin-bottom: 20px;
}

.cart-items {
  margin: 20px 0;
}

.cart-item {
  display: flex;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid #ebeef5;
}

.item-info {
  flex: 1;
}

.item-info h4 {
  margin: 0;
  font-size: 16px;
  color: #303133;
}

.gift-type {
  margin: 4px 0 0;
  font-size: 14px;
  color: #909399;
}

.item-quantity {
  margin: 0 20px;
  color: #606266;
}

.item-price {
  font-size: 16px;
  font-weight: 500;
  color: #f56c6c;
  width: 100px;
  text-align: right;
}

.order-total {
  margin-top: 20px;
  padding-top: 20px;
  /* border-top: 1px solid #ebeef5; */
  text-align: right;
}

.total-label {
  font-size: 16px;
  color: #606266;
  margin-right: 12px;
}

.total-amount {
  font-size: 20px;
  font-weight: 600;
  color: #f56c6c;
}

.submit-section {
  margin-top: 30px;
  text-align: center;
}
</style>