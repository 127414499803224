import { ref, computed } from 'vue'

const cart = ref([])

export const useCart = () => {
  const initCart = () => {
    try {
      const savedCart = localStorage.getItem('cart')
      if (savedCart) {
        cart.value = JSON.parse(savedCart)
      }
    } catch (error) {
      console.error('Error loading cart from localStorage:', error)
      cart.value = []
    }
  }

  const updateLocalStorage = () => {
    localStorage.setItem('cart', JSON.stringify(cart.value))
  }

  const addToCart = (product, giftType) => {

    const giftPrices = {
      small: 10,
      medium: 20,
      large: 30
    }

    const existingItem = cart.value.find(
      item => item.name === product.name && item.giftType === giftType
    )

    const totalPrice = product.price + giftPrices[giftType]

    if (existingItem) {
      existingItem.quantity++
    } else {
      cart.value.push({
        name: product.name,
        giftType,
        price: totalPrice,  // 商品价格 + 礼盒价格
        quantity: 1
      })
    }
    updateLocalStorage()
  }

  const cartCount = computed(() => {
    return cart.value.reduce((total, item) => total + item.quantity, 0) || 0
  })

  const totalAmount = computed(() => {
    return cart.value.reduce((total, item) => total + (item.price * item.quantity), 0) || 0
  })

  return {
    cart,
    cartCount,
    totalAmount,
    addToCart,
    initCart,
    updateLocalStorage
  }
} 