<template>
  <div>
    <el-button @click="showCart" type="primary" class="cart-button" size="large">
      <el-icon>
        <ShoppingCart />
      </el-icon>
      购物袋 ({{ cartCount }}) ¥{{ totalAmount }}
    </el-button>

    <el-drawer v-model="cartDrawerVisible" title="购物袋" direction="btt" size="60%">
      <div class="cart-content">
        <div v-if="cart.length === 0" class="empty-cart">
          <el-empty description="购物袋是空的" />
        </div>
        <div v-else class="cart-items">
          <div v-for="item in cart" :key="`${item.name}-${item.giftType}`" class="cart-item">
            <div class="cart-item-info">
              <h4>{{ item.name }}</h4>
              <p class="gift-type">{{ getGiftLabel(item.giftType) }}</p>
            </div>
            <div class="cart-item-quantity">
              <el-input-number v-model="item.quantity" :min="1" size="small" @change="updateCart" />
            </div>
            <div class="cart-item-price">
              ¥{{ item.price * item.quantity }}
            </div>
            <el-button type="danger" circle size="small" @click="removeFromCart(item)">
              <el-icon>
                <Delete />
              </el-icon>
            </el-button>
          </div>
        </div>
        <div class="cart-footer">
          <div class="total-amount">
            总计: ¥{{ totalAmount }}
          </div>
          <el-button type="primary" size="large" @click="checkout">
            结算
          </el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'  // 修改这行
import { ShoppingCart, Delete } from '@element-plus/icons-vue'
import { useCart } from '../services/cartService'

export default {
  name: 'ShoppingCartPage',
  components: { ShoppingCart, Delete },
  setup() {
    const router = useRouter()  // 添加这行
    const { cart, cartCount, totalAmount, initCart, updateLocalStorage } = useCart()
    const cartDrawerVisible = ref(false)

    // 初始化购物车数据
    onMounted(() => {
      initCart()
    })

    const removeFromCart = (item) => {
      const index = cart.value.indexOf(item)
      if (index > -1) {
        cart.value.splice(index, 1)
        updateLocalStorage()
      }
    }

    const updateCart = () => {
      updateLocalStorage()
    }

    const getGiftLabel = (type) => {
      const labels = {
        small: '小礼盒',
        medium: '中礼盒',
        large: '大礼盒'
      }
      return labels[type]
    }

    const showCart = () => {
      cartDrawerVisible.value = true
    }

    const checkout = () => {
      router.push('/order')  // 使用 router 实例
    }

    return {
      cart,
      cartCount,
      totalAmount,
      cartDrawerVisible,
      removeFromCart,
      updateCart,
      getGiftLabel,
      showCart,
      checkout
    }
  }
}
</script>

<style scoped>
.cart-button {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 1000;
  padding: 12px 24px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.cart-content {
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.cart-items {
  flex: 1;
  overflow-y: auto;
}

.cart-item {
  display: flex;
  align-items: center;
  padding: 6px 5px;
  border-bottom: 1px solid #ebeef5;
  gap: 6px;
}

.cart-item-info {
  width: 300px;
  flex: 1;
}

.cart-item-info h4 {
  margin: 0;
  font-size: 16px;
  color: #303133;
}

.gift-type {
  margin: 4px 0 0;
  font-size: 14px;
  color: #909399;
}

.cart-item-quantity {
  width: 120px;
}

.cart-item-price {
  font-size: 16px;
  font-weight: 500;
  color: #f56c6c;
  width: 50px;
  text-align: right;
}

.cart-footer {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #ebeef5;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.total-amount {
  font-size: 18px;
  font-weight: 500;
  color: #f56c6c;
}

.empty-cart {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>