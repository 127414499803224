import { createApp } from 'vue'
import App from './App.vue'
import router from './router'  // 引入路由配置
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn';
const app = createApp(App)
app.use(ElementPlus, {
    locale: zhCn, // 设置为中文
  })  // 使用 Element Plus
app.use(router)  // 使用 Vue Router
app.mount('#app')