<template>
	<div class="product-list">
		
		<div v-for="product in products" :key="product.id" class="product-card">
      <div class="product-content" @click="goToDetail(product.id)">
        <div class="product-image-container">
          <img :src="getImageUrl(product.description)" alt="商品缩略图" class="product-image" />
        </div>
        <div class="product-info">
          <h3 class="product-title">{{ product.name }}</h3>
          <p class="product-price">¥ {{ product.price }} <span style="font-size: 12px;">起</span></p>
          <div class="product-actions">
            <el-button 
              @click.stop="addToCart(product)" 
              class="add-to-cart-btn" 
              type="primary" 
              round
              size="small">
              <el-icon><Plus /></el-icon> 加入购物车
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="header-actions">
			<el-button @click="goToOrderHistory" type="info" plain>
				我的订单
			</el-button>
		</div>
    <gift-selector
      v-model:visible="giftSelectorVisible"
      :product="selectedProduct"
      @select="handleGiftSelect"
    />

    <shopping-cart />
	</div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { Plus } from '@element-plus/icons-vue'
import GiftSelector from './GiftSelector.vue'
import ShoppingCart from './ShoppingCart.vue'
import { useRouter } from 'vue-router'
import { useCart } from '../services/cartService'
export default {
	name: 'ProductList',
	components: { Plus, GiftSelector, ShoppingCart },
	setup() {
		const router = useRouter()
		const products = ref([])
		const giftSelectorVisible = ref(false)
		const selectedProduct = ref(null)

		// 处理图片 URL
		const getImageUrl = (description) => {
      try {
        const imageList = JSON.parse(description)
        return `https://www.maiyanhua.com.cn/${imageList[0]}`
      } catch (error) {
        console.error('Error parsing image description:', error)
        return '' // 返回空字符串或默认图片 URL
      }
    }
		// 获取商品列表
		onMounted(() => {
			fetch('https://www.maiyanhua.com.cn/api/products', {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
				},
			})
				.then(res => res.json())
				.then(data => {
					console.log(data)
					products.value = data
          localStorage.setItem('products', JSON.stringify(data))
				})
				.catch(error => {
					console.error('Error fetching products:', error);
				});
		})

		// 加入购物袋
		const addToCart = (product) => {
			selectedProduct.value = product
			giftSelectorVisible.value = true
		}

    const handleGiftSelect = (giftType) => {
      const { addToCart } = useCart()
      addToCart(selectedProduct.value, giftType)
    }

		const goToDetail = (productId) => {
			router.push(`/product/?id=${productId}`)
		}

		const goToOrderHistory = () => {
			router.push('/orders')
		}

		return {
			products,
			addToCart,
			selectedProduct,
			handleGiftSelect,
			getImageUrl,
			giftSelectorVisible,
			goToDetail,
			goToOrderHistory
		}
	}
}
</script>
<style scoped>
.product-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
}

.product-card {
  border: 1px solid #ebeef5;
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.3s;
  background: white;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,0.1);
}

.product-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 16px rgba(0,0,0,0.1);
}

.product-content {
  display: flex;
  cursor: pointer;
}

.product-image-container {
  width: 150px;
  height: 150px;
  flex-shrink: 0;
}

.product-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.product-info {
  flex: 1;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.product-title {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  color: #303133;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.product-price {
  margin: 10px 0;
  color: #f56c6c;
  font-size: 18px;
  font-weight: bold;
}

.product-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: auto;
}

.add-to-cart-btn {
  width: 90px;
  height: 30px;
  padding: 8px;
}

.cart-button {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 1000;
}

.cart-content {
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.cart-items {
  flex: 1;
  overflow-y: auto;
}

.cart-item {
  display: flex;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #ebeef5;
  gap: 16px;
}

.cart-item-info {
  flex: 1;
}

.cart-item-info h4 {
  margin: 0;
  font-size: 16px;
  color: #303133;
}

.gift-type {
  margin: 4px 0 0;
  font-size: 14px;
  color: #909399;
}

.cart-item-quantity {
  width: 120px;
}

.cart-item-price {
  font-size: 16px;
  font-weight: 500;
  color: #f56c6c;
  width: 100px;
  text-align: right;
}

.cart-footer {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #ebeef5;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.total-amount {
  font-size: 18px;
  font-weight: 500;
  color: #f56c6c;
}

.cart-button {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 1000;
  padding: 12px 24px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.empty-cart {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.header-actions {
  position: fixed;
  left: 20px;
  bottom: 40px;
  z-index: 1000;
}

.header-actions .el-button {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 12px 20px;
  border-radius: 8px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.header-actions .el-icon {
  margin-right: 4px;
}
</style>
